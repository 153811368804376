<template>
  <div class="vp-grid vp-h-full vp-items-stretch vp-grid-cols-[76px,1fr]">
    <aside class="vp-overflow-auto">
      <TheSidebar product="catalog">
        <template #version>
          <span>v{{ $options.version }}</span>
        </template>
      </TheSidebar>
    </aside>
    <slot></slot>
  </div>
</template>

<script>
import { version } from "../../package.json";
import TheSidebar from "components/src/sidebar/Index.vue";

export default {
  version,
  components: {
    TheSidebar,
  },
};
</script>
