<template>
  <div class="vp-p-4">
    <VpAlert
      v-if="creditsWarning"
      title="Credits Required"
      :icon="$options.icons.Alert"
      theme="muted"
    >
      <p>
        Your customers need to login into their account to download the digital
        content they purchased. <br />For the login feature to work, you will
        need Vepaar Credits.
      </p>
      <div class="vp-mt-3 vp-flex vp-space-x-2">
        <VyButton
          :icon="$options.icons.Add"
          @click.native="toWeb('credits')"
          label="Buy Credits"
          class="button--warning button--solid button--md button--rounded"
        />

        <DocsLink #default="{ creditForDigitalProduct }">
          <VyButton
            :href="creditForDigitalProduct"
            target="_blank"
            label="Know More"
            class="button--warning button--outline button--md button--rounded"
          />
        </DocsLink>
      </div>
    </VpAlert>
    <VpVueForm
      :fields="fields"
      :id="productId"
      :save="save"
      :get="get"
      :archive="move"
      :del="move"
      width="400px"
      #default="{
        state,
        response,
        addToAPIQueue,
        removeFromAPIQueue,
        isCreating,
      }"
      :track="track"
      ref="item"
      track-prefix="Product"
      cache="product"
      collection-cache="products"
      create-label="Continue"
      :form-state.sync="formState"
      feature="PRODUCT"
    >
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Product not accessible!"
        desc="This product is not accessible on store."
      />

      <VpAlert
        v-if="
          !isCreating && state.type === 'variable' && !response.variants.length
        "
        title="Variants Not Created!"
        :icon="$options.icons.Alert"
        theme="muted"
      >
        <p>
          Variable product needs atleast one variant to be listed on your store.
        </p>
        <div class="vp-mt-3 vp-flex vp-space-x-2">
          <VyButton
            :icon="$options.icons.Add"
            :to="{ name: 'product-item-variants' }"
            label="Add Variant"
            class="vp-cursor-pointer button--warning button--solid button--md button--rounded"
          />
          <VpFeature the-key="PRODUCT_VARIANT" #default="{ feature }">
            <VyButton
              :href="feature.documentation"
              target="_blank"
              label="Know More"
              class="button--warning button--outline button--md button--rounded"
            />
          </VpFeature>
        </div>
      </VpAlert>

      <VpField label="Product Type">
        <h4 class="vp-capitalize vp-text-lg vp-font-bold">{{ state.type }}</h4>
      </VpField>

      <VpField rules="required" label="Name" name="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>
      <VpField rules="required" label="Category" name="Category">
        <VueSelect
          :disabled="!checkPermission('PRODUCT_CATEGORY', 'upsert')"
          v-model="state.categoryIds"
          :options="
            checkPermission('PRODUCT_CATEGORY', 'read') ? getCategories : []
          "
          taggable
          :isMultiple="true"
          @input="onCategoryChange($event, addToAPIQueue, removeFromAPIQueue)"
        >
          <template #no-options>
            <p>No category found. Start typing to create a new one.</p>
          </template>
        </VueSelect>
      </VpField>

      <price
        :price.sync="state.price"
        :regular-price.sync="state.regularPrice"
      />

      <!-- SKU -->
      <VpField
        v-if="state.type == 'simple'"
        optional
        label="SKU (Stock Keeping Unit)"
        name="SKU (Stock Keeping Unit)"
      >
        <VpInput>
          <VpTextBox v-model="state.sku" />
        </VpInput>
      </VpField>

      <VpField label="Description" name="Description" optional>
        <VpTextArea v-model="state.desc" :rows="5" />
      </VpField>

      <VpField
        v-if="state.type == 'simple'"
        optional
        label="Measurement"
        name="Measurement"
        note="Examples: 500 gm, 1 pc, 5 kgs"
      >
        <VpInput>
          <VpTextBox v-model="state.measurementWithUnit" />
        </VpInput>
      </VpField>

      <WeightInput v-model.number="state.weight" />

      <VpField
        optional
        label="Link for more details"
        name="Link for more details"
        note="Link will be added at the bottom of description. Link must start with http:// or https://"
        rules="url"
      >
        <VpInput>
          <VpTextBox
            v-model="state.moreUrl"
            placeholder="https://www.example.com"
          />
        </VpInput>
      </VpField>

      <VpField
        v-if="state.type == 'simple' || state.type == 'digital'"
        inline
        label="Allow Multiple Quantity in Cart"
        note="Disable this if the product is unique and customer can only add single quantity of item."
      >
        <VpSwitch id="product-qty-options" v-model="state.allowQty" />
      </VpField>

      <VpField
        inline
        label="Out of Stock"
        note="Turning this on will show the product as 'out of stock' even if the stock is available."
      >
        <VpSwitch id="outOfStock" v-model="state.isOutOfStock" />
      </VpField>
      <!-- No need to provide manange inventory for variable product since we are already providing in variants item  -->
      <VpField
        v-if="state.type !== 'variable'"
        inline
        label="Manage Inventory"
        desc="Turn on to manage stock inventory"
      >
        <VpSwitch
          id="manageInventory"
          size="xs"
          shape="pill"
          color="primary"
          v-model="state.manageInventory"
        />
      </VpField>
      <VpStatusInput
        v-model="state.status"
        desc="Inactive products will not be listed in store."
      />
    </VpVueForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "graph/product/get.gql";
import upsert from "graph/product/upsert.gql";
import del from "graph/product/delete.gql";
import createCategory from "graph/productCategory/upsert.gql";
import fetchCategories from "graph/productCategory/collection.gql";

import Price from "./_Price.vue";
import { Alert, Add } from "icons/icons.js";
import VueSelect from "components/src/vue-select.vue";
import WeightInput from "./_weight-input.vue";

import DocsLink from "components/src/docs-link/main.vue";
import { toWeb } from "plugins/utils.js";

export default {
  icons: {
    Alert,
    Add,
  },
  props: {
    productId: [Number, String],
    categoryId: [Number, String],
    refreshFeature: Function,
  },
  components: {
    WeightInput,
    Price,
    VueSelect,
    DocsLink,
  },

  data() {
    return {
      formState: null,
      fields: [
        "id",
        "name",
        "desc",
        "sku",
        "regularPrice",
        "price",
        {
          name: "categoryIds",
          value: [],
        },
        "measurementWithUnit",
        "moreUrl",
        { name: "type", value: this.$route.query.type || "simple" },
        { name: "isOutOfStock", value: false },
        { name: "manageInventory", value: false },
        { name: "status", value: "active" },
        { name: "allowQty", value: true },
        "weight",
      ],
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
      weightUnit: "store/weightUnit",

      checkPermission: "user/checkPermission",
    }),
    ...mapGetters(["credits"]),

    creditsWarning() {
      return this.formState?.type === "digital" && this.credits <= 0;
    },

    track() {
      return {
        id: "Id",
        name: "Name",
        type: "Type",
        category: {
          key: "Category",
          value: (category) => category[0]?.name ?? "",
        },
        regularPrice: {
          key: "Regular Price",
          value: (regularPrice) =>
            regularPrice ? `${this.currency} ${regularPrice}` : "",
        },
        price: {
          key: "Price",
          value: (price) => `${this.currency} ${price}`,
        },
        allowQty: "Allow Multiple Quantity in Cart",
        measurementWithUnit: {
          key: "Measurement",
          value: (measurementWithUnit) => measurementWithUnit || "",
        },
        sku: {
          key: "SKU",
          value: (sku) => sku || "",
        },
        desc: {
          key: "Description",
          value: (desc) => desc || "",
        },
        moreUrl: {
          key: "Link for more details",
          value: (moreUrl) => moreUrl || "",
        },
        isOutOfStock: "Out of Stock",
        status: "Status",
      };
    },
  },

  methods: {
    toWeb,
    getCategories() {
      return this.$query(fetchCategories, {
        perPage: -1,
        page: 1,
      })
        .then((res) => res.data.productCategories.data)
        .then((res) => {
          // Pre-select category based on Route
          const preSelectCategoryId = this.$route.query["category-id"];
          const preSelectCategory = res.find(
            (item) => item.id == preSelectCategoryId
          );
          if (preSelectCategory) {
            this.$refs.item.setValue("categoryIds", [
              {
                label: preSelectCategory.name,
                value: preSelectCategory.id,
              },
            ]);
          }

          return res.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        });
    },

    get(id) {
      return this.$query(get, {
        id: Number(id),
      }).then(({ data }) => {
        const product = { ...data.product };
        product.categoryIds = product.category.map((category) => ({
          label: category.name,
          value: String(category.id),
        }));
        return {
          values: product,
          res: data,
        };
      });
    },

    /**
     * Preparing the payload for saving the form.
     */
    prepareSaveData(data, apiQueueResponse) {
      const updatedData = this.processQueueResponse(data, apiQueueResponse);
      if (!updatedData.regularPrice) {
        updatedData.regularPrice = null;
      }
      /**
       * As per new changes, if not coming from apiQueue, categoryIds will be as array of objects.
       */
      if (Array.isArray(updatedData.categoryIds)) {
        updatedData.categoryIds = updatedData.categoryIds.map((category) =>
          Number(category.value)
        );
      }
      return updatedData;
    },

    save(id, data, apiQueueResponse) {
      data = this.prepareSaveData(data, apiQueueResponse);
      const payload = {
        ...data,
        id: data.id ? Number(data.id) : undefined,
        price: Number(data.price),
        regularPrice: parseFloat(data.regularPrice),
        weight: data.weight ? data.weight : null,
      };
      return this.$mutate(upsert, {
        input: {
          id: id ? Number(id) : undefined,
          ...payload,
        },
      }).then(({ data }) => {
        if (this.refreshFeature) this.refreshFeature(true);
        if (!id) {
          this.$router.push({
            name: "product-media",
            params: { productId: Number(data.upsertProduct.id) },
          });
        } else {
          if (this.categoryId) {
            this.$router.push({
              name: "products-by-category",
              params: { categoryId: Number(data.upsertProduct.category[0].id) },
            });
          } else {
            this.$router.push({
              name: "products",
            });
          }
        }
        return data;
      });
    },

    move(id, action) {
      return this.$mutate(del, {
        id: Number(id),
        action,
      }).then(({ data }) => {
        if (this.refreshFeature) this.refreshFeature(true);

        this.$router.push({
          name: "products",
        });
        return data;
      });
    },

    /**
     * Add Category Mutation Function with evicting cache.
     */
    addCategory(value) {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "productCategories",
      });
      return this.$mutate(createCategory, {
        input: {
          name: value,
          status: "active",
        },
      });
    },

    onCategoryChange(value, addToAPIQueue, removeFromAPIQueue) {
      for (const selected of value) {
        if (
          selected?.value === "-" &&
          selected?.label !== (null || "" || undefined)
        ) {
          addToAPIQueue({
            key: `category-${selected.label}`,
            value: () => this.addCategory(selected.label),
          });
        } else if (!selected?.value) {
          removeFromAPIQueue(`category-${selected.label}`);
        }
      }
    },

    /**
     * Updating the form data with API Queue Response.
     */
    processQueueResponse(data, response) {
      if (response.length > 0) {
        for (const res of response) {
          if (res.upsertProductCategory) {
            const tempCategories = data.categoryIds.map((category) => {
              if (res.upsertProductCategory.name === category.label) {
                category.value = res.upsertProductCategory.id;
              }
              return category;
            });
            data.categoryIds = tempCategories;
          }
        }
      }
      return data;
    },
  },
};
</script>
