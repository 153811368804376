import product from "./product";
import ProductsIndex from "@/views/products/Index.vue";
import ProductsItem from "@/views/products/Item.vue";
import ProductIndex from "@/views/product/Index.vue";
import BulkIndex from "@/views/product/bulk/Index.vue";
import History from "@/views/product/bulk/History.vue";
import BulkImport from "@/views/product/bulk/Import.vue";
import Update from "@/views/product/bulk/Update.vue";

export default [
  {
    path: "products",
    component: ProductsIndex,
    children: [
      {
        path: "",
        name: "products",
        component: ProductsItem,
        meta: {
          label: "Products",
          breadcrumbs: ["products"],
        },
      },
      {
        path: ":productId",
        name: "product-item",
        component: ProductIndex,
        props: true,
        meta: {
          label: "Products",
          breadcrumbs: ["products", "product"],
        },
        children: product,
      },

      {
        path: "products/by-category",
        redirect: "products",
      },

      {
        path: "products/by-category/:categoryId",
        name: "products-by-category",
        component: ProductsItem,
        props: true,
        meta: {
          label: "Products",
          breadcrumbs: ["products", "products-by-category"],
        },
      },

      {
        path: "products/bulk",
        component: BulkIndex,
        children: [
          {
            path: "",
            name: "products-bulk",
            redirect: "history",
            meta: {
              label: "Bulk Actions",
              breadcrumbs: ["products", "products-bulk", "contacts-bulk"],
            },
          },
          {
            path: "history",
            name: "products-bulk-history",
            component: History,
            meta: {
              label: "History",
              breadcrumbs: [
                "products",
                "products-bulk",
                "products-history",
                "products-bulk-history",
              ],
            },
          },
          {
            path: "import",
            name: "products-bulk-import",
            component: BulkImport,
            meta: {
              label: "Import",
              breadcrumbs: [
                "products",
                "products-bulk",
                "products-import",
                "products-bulk-import",
              ],
            },
          },
          {
            path: "update",
            name: "products-bulk-update",
            component: Update,
            meta: {
              label: "Update",
              breadcrumbs: [
                "products",
                "products-bulk",
                "products-update",
                "products-bulk-update",
              ],
            },
          },
        ],
      },
    ],
  },
];
