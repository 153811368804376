import storeCharges from "@/views/product-charges/reorder.gql";
// PRODUCTS
import productMedias from "@/views/product/media/reorder.gql";
import productContents from "@/views/product/content/reorder.gql";
import productAssignedAttributes from "graph/productAssignedAttribute/reorder.gql";
import productAttributeOptions from "graph/productAttributeOption/reorder.gql";
import productCategories from "graph/productCategory/reorder.gql";
import tags from "graph/tag/reorder.gql";
import products from "graph/product/reorder.gql";
export default {
  products,
  tags,
  productCategories,
  productAssignedAttributes,
  productAttributeOptions,
  productMedias,
  storeCharges,
  productContents,
};
