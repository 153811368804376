<template>
  <div>
    <portal to="header">
      <VpButtonSm
        label="Manage Custom Fields"
        :href="`${$options.VITE_STORE_URL}/redirect?to=store-custom-fields`"
      />
    </portal>
    <VpLoaderContainer class="vp-py-12" v-if="isLoading" loading />
    <div class="vp-p-4 vp-w-1/2" v-else-if="customFields.length > 0">
      <ValidationObserver tag="div" class="space-y-4" ref="formObserver">
        <ValidationProvider
          v-for="customField in customFields"
          :key="`field--${customField.id}`"
          slim
          tag="div"
          :name="`${customField.id}`"
          :rules="customField?.validations?.required ? 'required' : ''"
        >
          <VpField
            class="vp-mb-4"
            :label="customField.name"
            :name="customField.name"
            :rules="customField?.validations?.required ? 'required' : ''"
          >
            <CustomField v-model="customField.value" :field="customField" />
          </VpField>
        </ValidationProvider>
      </ValidationObserver>
      <VyButton
        label="Save"
        type="submit"
        @click.native="save"
        class="button--primary button--solid button--rounded button--right button--md vp-flex-none vp-mt-2"
      />
    </div>
    <VpErrorPage
      v-else
      title="No Custom Field Created!"
      desc="Create additional custom field to apply on products. For Example: Material Type, Compatibility, Part Number, File Size etc."
      label="Create Custom Field"
    >
      <template #actions>
        <VyButton
          label="Create Custom Field"
          class="button--primary button--solid button--md button--rounded"
          :href="`${$options.VITE_STORE_URL}/redirect?to=store-custom-fields`"
        />
      </template>
    </VpErrorPage>
  </div>
</template>

<script>
import CustomField from "components/src/custom-field.vue";

import get from "graph/product/get.gql";
import upsert from "graph/product/updateProductCustomField.gql";
const { VITE_STORE_URL } = import.meta.env;

export default {
  VITE_STORE_URL,
  components: {
    CustomField,
  },

  props: {
    productId: [Number, String],
  },

  data() {
    return {
      customFields: [],
      isLoading: false,
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    get() {
      this.isLoading = true;
      return this.$query(get, {
        id: Number(this.productId),
      })
        .then(({ data }) => {
          const product = { ...data.product };
          this.customFields = product.customFields;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async save() {
      const isValid = await this.$refs.formObserver.validate();
      if (!isValid) {
        return;
      }
      this.customFields.forEach((item) => {
        delete item.__typename;
      });
      const selectedCustomFields = this.customFields.map(({ id, value }) => ({
        id,
        value,
      }));

      this.$mutate(upsert, {
        input: {
          customFields: selectedCustomFields,
        },
        id: Number(this.productId),
      })
        .then(() => {
          this.$vayu.notify({
            message: "Custom fields updated.",
            state: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          this.$vayu.notify({
            message: err,
            state: "danger",
          });
        });
    },
  },
};
</script>
