<template>
  <Loader v-if="loading" />

  <!-- Hydrated -->
  <div
    v-else
    class="vp-grid vp-h-full vp-items-stretch vp-grid-cols-[76px,1fr]"
  >
    <!-- Aside -->
    <aside class="vp-overflow-auto">
      <Sidebar product="catalog">
        <template #version>
          <span>v{{ $options.version }}</span>
        </template>
      </Sidebar>
    </aside>

    <main class="vp-overflow-auto">
      <div class="vp-flex vp-h-full vp-flex-col">
        <EmailNotVerified />
        <AccountDowngraded />
        <!-- Header -->
        <div
          class="vp-h-12 vp-flex vp-items-stretch vp-border-b vp-relative vp-z-20"
        >
          <div>
            <SwitchBiz />
          </div>
          <Breadcrumbs class="vp-flex-grow" />
          <div class="vp-ml-auto vp-flex vp-items-center vp-px-2">
            <PortalTarget name="header" slim />

            <TheNotification
              :is-notification="unreadNotificationCount ? true : false"
            />

            <VyButton
              v-tooltip="'Visit Store'"
              :icon="$options.icons.OpenInNew"
              class="button--gray button--rounded button--md -vp-mx-2"
              :href="bizUrl"
              target="_blank"
            />
          </div>
        </div>
        <!-- Content -->

        <!-- Handle Scroll issue -->
        <div class="vp-flex-grow vp-overflow-auto">
          <VpSubShell>
            <template #nav>
              <VpSubNavItem
                label="Products"
                :icon="$options.icons.Product"
                :to="{ name: 'products' }"
              />
              <VpSubNavItem
                label="Categories"
                :icon="$options.icons.Category"
                :to="{ name: 'product-categories' }"
              />
              <VpSubNavItem
                label="Badges"
                :icon="$options.icons.Badge"
                :to="{ name: 'product-badges' }"
              />

              <VpSubNavItem
                label="Attributes"
                :icon="$options.icons.Attribute"
                :to="{ name: 'product-attributes' }"
              />

              <VpSubNavItem
                label="Charges"
                :icon="$options.icons.Cash"
                :to="{ name: 'product-charges' }"
              />

              <VpSubNavItem
                label="Inventory"
                :icon="$options.icons.Inventory"
                :to="{ name: 'product-inventory' }"
              />
            </template>
          </VpSubShell>
        </div>
      </div>
      <FrillWidget />
    </main>
  </div>
</template>

<script>
import { version } from "../../../package.json";
import EmailNotVerified from "components/src/email/not-verified.vue";
import Sidebar from "components/src/sidebar/Index.vue";
import hydrate from "./get.gql";
import { getCampaign, setCampaign } from "plugins/utils.js";
import TheNotification from "components/src/notifications/header-button.vue";
import SwitchBiz from "components/src/biz-display.vue";
import FrillWidget from "components/src/frill-widget.vue";

import {
  ArrowRight,
  Menu,
  Coupon,
  AlertMessage,
  Twitter,
  Attribute,
  Badge,
  Banners,
  About,
  Cart,
  ContactBook,
  Document,
  Cash,
  Category,
  Checkout,
  Whatsapp,
  Dashboard,
  Inventory,
  OpenInNew,
  Payment,
  Product,
  Rating,
  Group,
  Settings,
  Sitemap,
  Theme,
  Link,
  Notification,
  History,
} from "icons/icons.js";
import AccountDowngraded from "components/src/account/downgraded.vue";
import Breadcrumbs from "components/src/app/breadcrumbs.vue";
import { mapActions, mapGetters } from "vuex";
import Loader from "components/src/app/loader.vue";
import { toWeb } from "plugins/utils.js";
import unreadNotification from "components/src/notifications/unread-count.gql";

export default {
  version,
  icons: {
    ArrowRight,
    History,
    Coupon,
    Menu,
    About,
    AlertMessage,
    Attribute,
    Badge,
    ContactBook,
    Theme,
    Banners,
    Cart,
    Cash,
    Twitter,
    Category,
    Document,
    Checkout,
    Whatsapp,
    Dashboard,
    Inventory,
    OpenInNew,
    Payment,
    Product,
    Group,
    Rating,
    Settings,
    Sitemap,
    Link,
    Notification,
  },
  components: {
    TheNotification,
    FrillWidget,
    Sidebar,
    EmailNotVerified,
    AccountDowngraded,
    Breadcrumbs,
    Loader,
    SwitchBiz,
  },
  computed: {
    ...mapGetters({
      bizUrl: "biz/url",
      isStoreProductSetup: "biz/isStoreProductSetup",
    }),
  },
  mounted() {
    this.$root.$on("rehydrate", (campaignId, web, serviceWorker) => {
      if (campaignId) {
        setCampaign(campaignId);
      }
      this.rehydrate(web, serviceWorker);
    });
  },
  created() {
    this.startHydration();
  },
  data() {
    return {
      loading: false,
      unreadNotificationCount: 0,
    };
  },
  methods: {
    ...mapActions({
      hydrate: "hydrate",
    }),
    rehydrate(web, serviceWorker = true) {
      /**
       * Need to register prototypes in side created hook to have access to component instance.
       * We're connecting with the Chrome Extension
       * When any change is done in admin panel which needs to be updated in extension too,
       * we sent a rehydrate request to extension service worker script.
       *
       * https://developer.chrome.com/docs/extensions/mv2/messaging/
       */

      //Rehydrate web platform only if required
      if (web) {
        this.$cache.evict({
          id: "ROOT_QUERY",
        });
        this.startHydration(serviceWorker);
      }
    },
    startHydration(serviceWorker) {
      if (!serviceWorker) {
        this.loading = true;
      }
      this.loading = true;

      this.hydrate({
        request: this.$query(hydrate, {
          campaignId: getCampaign(),
          userId: null,
        }),
        version,
      })
        .then((res) => {
          console.log(res);
          if (!res.campaign.vepaarProducts.stores.length)
            this.$router.push({ name: "welcome" });

          this.updateNotificationCount(res.user.id);
        })
        .catch((err) => {
          console.log("🚀 ~ created ~ err:", err);
          toWeb("logout");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateNotificationCount(userId) {
      this.$query(unreadNotification, {
        userId,
        filters: {
          readAt: false,
          product: "store",
        },
      }).then(({ data }) => {
        this.unreadNotificationCount = data.notifications.pagination.total;
      });
    },
  },
};
</script>
