<template>
  <Create :product-id="productId">
    <!-- Need to keep empty default slot here to keep reactivity -->
    <VpRequest
      key="create-variant-bulk"
      ref="request"
      class="vp-p-6 vp-min-w-[700px] vp-overflow-auto"
      :get="get"
      #default="{}"
    >
      <VpPageHeader title="1. Select Options">
        To create Product Variant permutations, at least one option from each
        attribute is required.
      </VpPageHeader>

      <div class="vp-space-y-4 vp-mt-6">
        <VpField
          v-for="(attr, index) in attributes"
          :label="attr.name"
          :key="`attr-${index}`"
        >
          <div>
            <ProductAttributeInput
              :ui-element="
                attr.uiElement == 'color-palette' ? 'color-palette' : 'radio'
              "
              :options="attr.options"
              v-model="enabledOptions"
              :selected="existingOptions"
            />
          </div>
        </VpField>
      </div>

      <VpPageHeader
        class="vp-mt-12 vp-pt-12 vp-border-t"
        title="2. Customize Variants"
      >
        Choose options, view permutations. Click "Save" to create all variants.
        Enter Variant selling price if you choose to differentiate that price,
        else leave it blank as base selling price.
      </VpPageHeader>

      <VyTable
        class="vp-w-full vp-mt-6 table--relaxed table--border-y"
        v-if="permutations && permutations.length > 0"
      >
        <template #default>
          <tr>
            <th class="vp-text-start" width="70px">
              <VpIcon
                :name="$options.icons.Help"
                class="vp-text-gray-400 vp-w-6 vp-h-6"
                v-tooltip="
                  'Shows if the variant is already created or will be created based on attributes.'
                "
              />
            </th>

            <th
              class="vp-text-start"
              v-for="(col, colIndex) in permutations[0].attributes"
              :key="`head-${colIndex}`"
            >
              {{ col.attribute.name }}
            </th>

            <th class="vp-text-start" width="150px">Base Selling Price</th>
            <th class="vp-text-start" width="150px">Variant Selling Price</th>
            <th v-if="weightUnit" class="vp-text-start" width="150px">
              <div class="vp-flex vp-gap-1 vp-items-center">
                Shipping Weight
                <VyIcon
                  :name="$options.icons.Info"
                  class="vp-text-gray-400 vp-text-xl"
                  v-tooltip="
                    'Shipping charges can be applied using this weight'
                  "
                />
              </div>
            </th>
            <th class="vp-text-start" width="150px">SKU</th>

            <th class="vp-text-end" width="140px">Status</th>
          </tr>
          <template v-for="(row, rowIndex) in permutations">
            <tr v-if="isPermutionEnabled(row)" :key="`row-${rowIndex}`">
              <td>
                <VpIcon
                  v-if="row.id"
                  :name="$options.icons.Check"
                  class="vp-text-success-500 vp-h-6 vp-w-6"
                  v-tooltip="
                    'This variant is already created, you can edit the values if required.'
                  "
                />
                <VpIcon
                  v-else
                  :name="$options.icons.NewLabel"
                  class="vp-text-warning-500 vp-h-6 vp-w-6"
                  v-tooltip="
                    'This is a new variant and will be created once you save.'
                  "
                />
              </td>
              <td
                v-for="(col, colIndex) in row.attributes"
                :key="`col-${colIndex}`"
              >
                <ProductAttribute
                  :has-label="false"
                  :option="col.option"
                  :attribute="col.attribute"
                />
              </td>
              <td>
                {{ product.price }}
              </td>
              <td>
                <VpField>
                  <VpInput>
                    <VpTextBox v-model="row.price" type="number" />
                  </VpInput>
                </VpField>
              </td>

              <td v-if="weightUnit">
                <VpField rules="positiveZeroAllowedFloat" name="Weight">
                  <VpInput :after="weightUnit">
                    <VpTextBox
                      v-if="row.id"
                      v-model.number="row.weight"
                      step="0.01"
                    />

                    <!-- Autofill product weight on create -->
                    <VpTextBox
                      v-else
                      v-model.number="product.weight"
                      step="0.01"
                    />
                  </VpInput>
                </VpField>
              </td>
              <td>
                <VpField>
                  <VpInput>
                    <VpTextBox v-model="row.sku" />
                  </VpInput>
                </VpField>
              </td>
              <td>
                <VpStatusInput
                  size="xxs"
                  label=""
                  :id="`status-${rowIndex}`"
                  class="permutation-status"
                  v-model="row.status"
                />
              </td>
            </tr>
          </template>
        </template>
      </VyTable>

      <VyButton
        label="Save"
        class="vp-mt-6 button--primary button--solid button--md button--rounded"
        @click.native="checkPermission('PRODUCT_VARIANT', 'upsert', save)"
        :loading="saving"
      />
    </VpRequest>
  </Create>
</template>

<script>
import { mapGetters } from "vuex";
import upsert from "graph/productVariant/upsert.gql";
import meta from "graph/productVariant/meta.gql";
import { isEqual, sortBy } from "lodash-es";

import Create from "./_Create.vue";
import { Check, NewLabel, Help, Info } from "icons/icons.js";
import ProductAttributeInput from "components/src/product-attribute-input.vue";
import ProductAttribute from "components/src/product-attribute.vue";

export default {
  icons: {
    Info,
    Check,
    NewLabel,
    Help,
  },
  props: {
    productId: [Number, String],
  },
  components: {
    Create,
    ProductAttributeInput,
    ProductAttribute,
  },

  data() {
    return {
      attributes: null,
      variants: null,
      permutations: null,
      enabledOptions: [],
      existingOptions: null,
      saving: false,
      product: null,
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      weightUnit: "store/weightUnit",
    }),
  },

  methods: {
    get() {
      return this.$query(meta, {
        id: Number(this.productId),
      }).then(({ data }) => {
        const { product, productVariants, productAssignedAttributes } = data;
        this.product = product;

        /**
         * Creating an array of attributeId-optionId to compare values later.
         */
        let productsVariant = productVariants.data;
        this.variants = productsVariant.map((variant) => {
          variant.attributeOptionPairs = this.attributeOptionPairs(
            variant.attributes
          );
          return variant;
        });

        this.attributes = productAssignedAttributes.data;

        this.permutations = this.generatePermutations(
          this.attributes,
          this.variants
        );

        /**
         * Create a set of optins which are already used to create variants
         * and show them preselected while creating permutations
         */
        this.existingOptions = this.variants.reduce((options, variant) => {
          return options.concat(
            variant.attributes.map((item) => item.option.id)
          );
        }, []);
      });
    },

    async save() {
      const variants = this.permutations
        .filter((permutation) => this.isPermutionEnabled(permutation))
        .map((variant) => {
          return {
            attributeInput: variant.attributes.map((item) => {
              return {
                attributeId: Number(item.attribute.id),
                optionId: Number(item.option.id),
              };
            }),
            weight: variant.weight || null,
            variantId: Number(variant.id),
            price: Number(variant.price) || this.product.price,
            regularPrice:
              Number(variant.regularPrice) || this.product.regularPrice,
            sku: variant.sku,
            status: variant.status,
          };
        });

      try {
        this.saving = true;
        await this.$mutate(upsert, {
          storeId: this.storeId,
          productId: Number(this.productId),
          input: variants,
        });
        this.$vayu.notify({
          title: "Saved",
          state: "success",
        });
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "product",
        });
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "productVariants",
        });
        this.$refs.request.refresh();
      } catch (err) {
        console.error(err);
        this.$vayu.notify({
          title: "Error",
          message: "Failed to save the variants.",
          state: "danger",
        });
      }
      this.saving = false;
    },

    attributeOptionPairs(attributes) {
      return attributes.map((value) => {
        return value.attribute.id + "-" + value.option.id;
      });
    },

    isPermutionEnabled(permuation) {
      const disabledOption = permuation.optionIds.find(
        (optionId) =>
          !this.existingOptions.includes(optionId) &&
          !this.enabledOptions.includes(optionId)
      );

      return disabledOption ? false : true;
    },

    generatePermutations(attributes) {
      const optionSets = attributes
        .filter((attribute) => attribute.options.length > 0)
        .map((attribute) => {
          return attribute.options.map((option) => {
            return {
              option,
              attribute: {
                uiElement: attribute.uiElement,
                id: attribute.id,
                name: attribute.name,
              },
            };
          });
        });

      /**
       * Create all the n x n combinations:
       * Didn't dig up how this works but it works!
       * https://stackoverflow.com/a/43053803/3165956
       */
      return optionSets
        .reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())))
        .map((permutation) => {
          /**
           * When there is only a single permutation possible, it does not come as array
           * hence explicitly creating an arrray.
           */
          if (!Array.isArray(permutation)) {
            permutation = [permutation];
          }

          /**
           * Finding existing variants and adding the values.
           * This will be used as v-model to save data later
           */

          const attributeOptionPairs = this.attributeOptionPairs(permutation);
          const existing = this.variants?.find((item) =>
            isEqual(
              sortBy(attributeOptionPairs),
              sortBy(item.attributeOptionPairs)
            )
          );

          let existingData;
          if (existing) {
            const { id, price, regularPrice, sku, status, weight } = existing;
            existingData = { id, price, regularPrice, sku, status, weight };
          }

          /**
           * List of option ids per permutation
           * This will help us filter out inactive option permutations
           */
          const optionIds = permutation.map((item) => item.option.id);

          return {
            optionIds,
            ...existingData,
            attributes: permutation,
          };
        });
    },
  },
};
</script>
