<template>
  <VpRequest ref="request" :get="get" #default="{ res }">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="tags"
      cache="tags"
      :request-payload="{ query: $options.tags, noFilterStorage: true }"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Badges"
      :search="false"
      :filters="false"
      feature="BADGE"
    >
      <template #empty>
        <VpErrorPage
          title="No Badge Found!"
          desc="To apply badges to products, you first need to create them!"
          label="Create a Badge"
          :to="{ name: 'product-badge', params: { id: '+' } }"
        />
      </template>

      <template #badge="{ item }">
        <Badge :emoji="item.emoji" :label="item.name" :color="item.color" />
      </template>
      <template #apply="{ item }">
        <VpSwitch
          :disabled="!checkPermission('PRODUCT', 'upsert')"
          :id="`badge-${item.id}`"
          :value="res.includes(item.id)"
          @input="toggle(item.id)"
        />
      </template>
    </VpList>
  </VpRequest>
</template>

<script>
import { mapGetters } from "vuex";
import get from "graph/product/badge/get.gql";
import toggle from "graph/product/badge/toggle.gql";
import Badge from "components/src/badge.vue";
import tags from "graph/tag/collection.gql";

export default {
  tags,
  props: {
    productId: [Number, String],
  },
  components: {
    Badge,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    params() {
      return {
        type: 4,

        filters: {
          status: "active",
        },
      };
    },
    columns() {
      return [
        { name: "badge", type: "string" },
        { name: "apply", type: "string", fix: true },
      ];
    },
  },

  methods: {
    get() {
      return this.$query(get, {
        id: Number(this.productId),
      }).then((res) => {
        return res.data.product.tags.map((item) => Number(item.id));
      });
    },

    async toggle(id) {
      try {
        await this.$mutate(toggle, {
          productId: Number(this.productId),
          tagId: id,
        }).then(() => {
          this.$refs.list.refresh();
          this.$cache.evict({
            id: "ROOT_QUERY",
            fieldName: "product",
          });
        });
      } catch (err) {
        console.error(err);
        this.$refs.request.refresh();
        this.$vayu.notify({
          title: "Error!",
          message:
            "There was an error while saving the data. Please refresh & try again",
          state: "danger",
        });
      }
    },
  },
};
</script>
