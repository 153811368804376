<template>
  <VpList
    :per-page="10"
    :columns="columns"
    endpoint="productVariantStocks"
    :request-payload="{ query: $options.productVariantStocks }"
    :params="{ productId }"
    :filters="filters"
    class="vp-h-full"
    ref="list"
    title="Product Variant Stocks"
    cache="productVariants"
    :search="false"
  >
    <template #empty>
      <VpErrorPage
        title="Stock Not Found!"
        desc="You must enable manage inventory to manage stock."
      />
    </template>
    <template #attributes="{ item }">
      <attributes :data="item.attributes" />
    </template>
    <template #stock="{ item }">
      <VpProductStock
        :value="item.stock"
        :is-out-of-stock="item.isOutOfStock"
      />
    </template>
    <template #stockAfter="{ item }">
      <VpProductStock :value="afterStock(item)" />
    </template>

    <template #adjust="{ item }">
      <VpField name="Stock">
        <VpInput>
          <VpTextBox
            type="number"
            :value="values[item.id]"
            @input="$set(values, item.id, $event)"
            step="1"
          />
        </VpInput>
      </VpField>
    </template>

    <template #save="{ item }">
      <VyButton
        label="Save"
        :loading="saving == item.id"
        :disabled="!canSave(item)"
        @click.native="save(item)"
        class="button--sm button--rounded"
        :class="{
          'button--primary button--solid': canSave(item),
          'button--gray button--muted': !canSave(item),
        }"
      />
    </template>
  </VpList>
</template>

<script>
import update from "graph/product/stock/update.gql";
import Attributes from "components/src/product-attributes-display.vue";
import productVariantStocks from "graph/product/stock/getVariants.gql";

export default {
  productVariantStocks,
  props: {
    productId: [Number, String],
  },
  components: {
    Attributes,
  },

  computed: {
    columns() {
      return [
        { name: "sku", type: "string", label: "SKU" },
        { name: "attributes", type: "string" },
        { name: "stock", label: "Stock", type: "string" },
        { name: "adjust", label: "Adjust (+/-)", type: "string" },
        { name: "stockAfter", label: "Stock After", type: "string" },
        { name: "save", label: "", type: "string" },
      ];
    },
  },

  data() {
    return {
      saving: null,
      values: {},
      filters: {
        manageInventory: true,
      },
    };
  },

  methods: {
    canSave(item) {
      return this.values[item.id];
    },

    afterStock(item) {
      const currentValue = item.stock || 0;
      const newValue = parseInt(this.values[item.id]) || 0;
      return currentValue + newValue;
    },

    save(item) {
      this.saving = item.id;

      return this.$mutate(update, {
        productId: Number(item.id),
        input: { stock: Number(this.values[item.id]) },
      }).then(() => {
        this.values = {};
        this.saving = null;
        this.$refs.list.refresh();
      });
    },
  },
};
</script>
<style scoped></style>
