<template>
  <VpShell
    class="vp-h-full vp-overflow-hidden"
    :key="productId"
    :aside="productId != '+'"
  >
    <template #nav>
      <div class="vp-w-full vp-h-full vp-p-4 vp-border-r vp-border-gray-200">
        <!-- Product Detail -->
        <div v-if="!product">
          <div class="shimmer w-full ratio vp-rounded-md" />
          <div class="shimmer vp-mt-6 vp-h-2 vp-rounded" />
          <div class="shimmer vp-mt-1 vp-h-6 vp-rounded" />
          <div class="shimmer vp-mt-6 vp-h-12 vp-rounded" />
          <div class="shimmer vp-mt-1 vp-h-12 vp-rounded" />
          <div class="shimmer vp-mt-1 vp-h-12 vp-rounded" />
          <div class="shimmer vp-mt-1 vp-h-12 vp-rounded" />
        </div>

        <div v-else>
          <VpProductMedia
            class="vp-cursor-pointer"
            :items="product.media"
            @click.native="
              $router.push({ name: 'product-media' }).catch((err) => {})
            "
          />
          <div class="vp-mt-6">
            <VpField label="Product Name">
              <h4 class="vp-leading-none vp-text-lg vp-font-bold">
                {{ product.name }}
              </h4>
            </VpField>
          </div>
          <VpSubNav class="vp-mt-6">
            <VpSubNavItem
              :to="{ name: 'product' }"
              :icon="$options.icons.Edit"
              label="Edit"
              exact
            />
            <!-- <VpSubNavItem
              :to="{ name: 'product-overview' }"
              :icon="$options.icons.Info"
              label="Description"
              exact
            /> -->

            <VpSubNavItem
              :to="{ name: 'product-media' }"
              :icon="$options.icons.Image"
              label="Media"
            />
            <VpSubNavItem
              :to="{ name: 'product-item-badges' }"
              :icon="$options.icons.Badge"
              label="Badges"
            />

            <template v-if="product.type == 'variable'">
              <VpSubNavItem
                :to="{ name: 'product-item-variants' }"
                :icon="$options.icons.ProductVariants"
                label="Variants"
              />
              <VpSubNavItem
                :to="{ name: 'product-item-attributes' }"
                :icon="$options.icons.Attribute"
                label="Attributes"
              />
            </template>

            <VpSubNavItem
              :to="{ name: 'product-item-charges' }"
              :icon="$options.icons.Cash"
              label="Charges"
            />
            <VpSubNavItem
              :to="{ name: 'product-item-stock' }"
              :icon="$options.icons.Inventory"
              label="Stock"
            />

            <template v-if="product.type == 'digital'">
              <VpSubNavItem
                :to="{ name: 'product-content' }"
                :icon="$options.icons.Document"
                label="Content"
              />
            </template>

            <VpSubNavItem
              :to="{ name: 'product-item-reviews' }"
              :icon="$options.icons.Rating"
              label="Reviews"
            />

            <VpSubNavItem
              v-if="product.type != 'variable'"
              :to="{ name: 'product-tiered-pricing' }"
              label="Tiered Pricing"
              :icon="$options.icons.Tiers"
            />

            <VpSubNavItem
              :to="{ name: 'product-custom-fields' }"
              :icon="$options.icons.TextBox"
              label="Custom Fields"
            />
          </VpSubNav>
        </div>
      </div>
    </template>

    <template #default>
      <router-view
        @refresh="get()"
        :type="product && product.type"
        :category-id="categoryId"
        :manage-inventory="product && product.manageInventory"
        :refresh-feature="feature && feature.refresh"
      />
    </template>
  </VpShell>
</template>

<script>
import info from "graph/product/info.gql";
import {
  Image,
  Badge,
  Cash,
  Inventory,
  Document,
  Rating,
  Tiers,
  TextBox,
  Attribute,
  ProductVariants,
  Edit,
  Info,
} from "icons/icons.js";

export default {
  icons: {
    Image,
    Badge,
    Cash,
    Inventory,
    Document,
    Rating,
    Tiers,
    TextBox,
    Attribute,
    ProductVariants,
    Edit,
    Info,
  },
  props: {
    productId: [Number, String],
    feature: Object,
  },
  data() {
    return {
      product: null,
      categoryId: this.$route.query?.categoryId ?? null,
    };
  },

  watch: {
    /**
     * When the product is created we are keeping user to product page only
     * which is same route as this and just ID chagnes
     * So watching and getting product details again
     */
    productId() {
      this.get();
    },
  },

  created() {
    this.get();
    const query = Object.assign({}, this.$route.query);
    if (query.categoryId) {
      delete query.categoryId;
      this.$router.replace({ query }).catch((err) => {
        console.error(err);
      });
    }
  },

  methods: {
    get() {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "product",
      });
      if (this.productId == "+") return;
      this.$query(info, {
        id: Number(this.productId),
        notificationId: this.$route.query.nId,
      })
        .then(({ data }) => data.product)
        .then((res) => {
          this.product = res;
          this.$root.$emit("breadcrumbs-label", "product", res.name);
        });
    },
  },
};
</script>
