<template>
  <VpField
    optional
    rules="positiveZeroAllowedFloat"
    label="Shipping Weight"
    name="Shipping Weight"
  >
    <template #default>
      <VpInput :after="weightUnit">
        <VpTextBox
          :value="value"
          type="number"
          @input="$emit('input', Number($event))"
          step="0.01"
        />
      </VpInput>
    </template>
  </VpField>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: Number,
  },
  computed: {
    ...mapGetters({
      weightUnit: "store/weightUnit",
    }),
  },
};
</script>
