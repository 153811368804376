<template>
  <div class="vp-flex vp-space-x-1">
    <VpFile
      label="Add Image"
      :icon="$options.icons.Image"
      @input="openCropper($event)"
      accept=".jpg,.jpeg,.png"
    />
    <VyButton
      :icon="$options.icons.Video"
      label="Add Video"
      class="button--sm button--rounded button--solid button--primary"
      @click.native="$vayu.modal.open('embed-video')"
    />

    <Cropper id="product" @input="saveImage" save-label="Crop and Upload" />
    <EmbedVideo :save="saveVideo" />
  </div>
</template>

<script>
import upsert from "graph/product/upsert.gql";

import Cropper from "components/src/cropper/index.vue";
import EmbedVideo from "./_embed-video.vue";
import { Image, Video, ChevronDown } from "icons/icons.js";
import { uploadMediaToS3 } from "utils/presets.js";
import get from "graph/product/get.gql";

export default {
  icons: {
    Image,
    Video,
    ChevronDown,
  },

  props: {
    productId: [Number, String],
  },
  data() {
    return {
      product: null,
    };
  },
  created() {
    this.$query(get, {
      id: Number(this.productId),
    }).then(({ data: { product } }) => {
      this.product = product;
    });
  },
  components: {
    Cropper,
    EmbedVideo,
  },

  methods: {
    openCropper(img) {
      this.$vayu.modal.open("crop-modal--product", {
        img,
        cropperConfig: {
          force: true,
          aspectRatio: 1,
        },
      });
    },

    saveVideo(_, data) {
      return this.$mutate(upsert, {
        input: {
          id: Number(this.productId),
          price: this.product.price,
          name: this.product.name,
          type: this.product.type,
          ...data,
        },
      }).then(({ data }) => {
        this.$vayu.modal.close("embed-video");
        this.$emit("refresh");
        return data;
      });
    },

    async saveImage({ fileName, fileType, value, fileSize }) {
      const { id } = await uploadMediaToS3({
        path: "product",
        file: {
          name: fileName,
          type: fileType,
          url: value,
          size: (fileSize / 1024 / 1024).toFixed(2) + "MB",
        },
      });

      return this.$mutate(upsert, {
        input: {
          id: Number(this.productId),
          name: this.product.name,
          type: this.product.type,
          price: this.product.price,
          mediaIds: [id],
        },
      }).then(({ data }) => {
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
