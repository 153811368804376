<template>
  <div>
    <VpPageHeader title="Available" class="vp-border-b vp-pb-2" />
    <VpList
      :columns="columns"
      endpoint="available-product-attributes"
      :request-payload="{ query: $options.productAttributes }"
      cache="productAttributes"
      class="vp-h-full"
      ref="list"
      title="Product Available Attributes"
      :search="false"
      :header="false"
      feature="PRODUCT_ATTRIBUTE"
    >
      <template #empty>
        <p class="vp-text-sm vp-text-gray-500 vp-mt-4">
          No attributes created yet. To create variants, please create a product
          attribute first.
        </p>
        <VpButtonSm
          class="vp-mt-4"
          label="Manage Product Attributes"
          :to="{ name: 'product-attributes' }"
        />
      </template>

      <template #options="{ item }">
        <div class="vp-flex vp-items-center vp-flex-nowrap">
          <p class="vp-text-gray-500 vp-mr-2">
            {{ item.options.length }} Options
          </p>
          <VpActionButton
            :icon="$options.icons.Add"
            v-tooltip="'Add New Option'"
            @click.native="addNew(item.id, item.uiElement)"
            class="button--xs button--gray"
          />
        </div>
      </template>

      <template #assign="{ item }">
        <VpIcon
          class="vp-text-success-500 vp-w-6 vp-h-6"
          v-if="assigned && assigned.includes(item.id)"
          :name="$options.icons.Check"
          v-tooltip="'Attribute is already assigned'"
        />
        <VyButton
          type="button"
          v-else-if="
            item.options.length > 0 &&
            checkPermission('PRODUCT_ATTRIBUTE', 'upsert')
          "
          :icon="$options.icons.Add"
          :loading="item.id == toEnable && saving"
          @click.native="assign(item.id)"
          class="button--primary button--solid button--rounded button--square button--sm"
        />
        <div
          v-else
          v-tooltip="
            `Atleast one option is required to assign an attribute to product.`
          "
        >
          <VyButton
            disabled
            :icon="$options.icons.Add"
            class="button--gray button--solid button--rounded button--square button--sm"
          />
        </div>
      </template>
    </VpList>

    <DefaultAttributeOption :id="toEnable" @select="save($event)" />

    <!-- Add New Option -->
    <TheOption
      :attribute-id="attributeIdForNewOption"
      :ui-element="uiElementForNewOption"
      @refresh="$refs.list.refresh()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import toggle from "graph/product/toggleAttribute.gql";
import getVariantCount from "graph/product/getVariantCount.gql";
import DefaultAttributeOption from "./_DefaultAttributeOption.vue";
import TheOption from "../../product-attribute/_Option.vue";
import { Check, Add } from "icons/icons.js";

import productAttributes from "graph/productAttribute/collection.gql";

export default {
  productAttributes,
  icons: {
    Check,
    Add,
  },
  props: {
    productId: [Number, String],
    assigned: Array,
  },
  data() {
    return {
      toEnable: null,
      saving: false,
      attributeIdForNewOption: null,
      uiElementForNewOption: null,
    };
  },
  components: {
    DefaultAttributeOption,
    TheOption,
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "name", type: "string" },
        { name: "options", type: "string" },
        { name: "assign", fix: true, type: "string" },
      ];
    },
  },

  methods: {
    addNew(id, uiElement) {
      this.attributeIdForNewOption = id;
      this.uiElementForNewOption = uiElement;
      this.$vayu.modal.open("product-attribute-option");
    },

    async save(optionId) {
      this.saving = true;

      try {
        await this.$mutate(toggle, {
          productId: Number(this.productId),
          attributeId: Number(this.toEnable),
          optionId,
        });
      } catch (err) {
        console.error(err);
        this.$vayu.notify({
          title: "Error!",
          message: "Failed to modify an attribute. Please refresh & try again.",
          state: "danger",
        });
      }

      this.saving = false;
      this.toEnable = null;
      this.$refs.list.refresh();
      this.$emit("refresh");
    },
    async assign(id) {
      this.toEnable = id;
      this.saving = true;
      const variantCount = await this.$query(getVariantCount, {
        id: Number(this.productId),
      }).then(({ data }) => data.product.variantCount);
      this.saving = false;

      if (variantCount > 0) {
        this.$vayu.modal.open("product-default-attribute");
      } else {
        this.save();
      }
    },
  },
};
</script>
