import Vue from "vue";
import VueRouter from "vue-router";
import Hydrate from "@/views/hydrate/index.vue";
import products from "./products";
import { getToken } from "plugins/utils";
import { pageView } from "plugins/segment";

import inventory from "./inventory";

import NotificationsIndex from "@/views/notifications/index.vue";

const { VITE_WEB_URL } = import.meta.env;

const ProductCategoryIndex = () => import("@/views/product-category/Index.vue");
const ProductCategoryList = () => import("@/views/product-category/List.vue");
const ProductCategoryItem = () => import("@/views/product-category/Item.vue");
const ProductBadgeList = () => import("@/views/product-badge/List.vue");
const ProductBadgeItem = () => import("@/views/product-badge/Item.vue");
const ProductAttributeIndex = () =>
  import("@/views/product-attribute/Index.vue");
const ProductAttributeList = () => import("@/views/product-attribute/List.vue");
const ProductAttributeItem = () => import("@/views/product-attribute/Item.vue");
const ProductChargesList = () => import("@/views/product-charges/List.vue");
const ProductChargesItem = () => import("@/views/product-charges/Item.vue");

// Open Routes coming from Extension
const Redirect = () => import("components/src/redirect.vue");

import TheWelcome from "@/views/welcome/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/welcome",
    name: "welcome",
    component: TheWelcome,
  },
  {
    path: "/",
    name: "home",
    component: Hydrate,
    children: [
      {
        path: "",
        redirect: "products",
        meta: {
          breadcrumbs: ["products"],
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: NotificationsIndex,
        meta: {
          label: "Notifications",
          breadcrumbs: ["notifications"],
        },
      },

      ...products,
      ...inventory,

      {
        path: "categories",
        component: ProductCategoryIndex,
        children: [
          {
            path: "",
            name: "product-categories",
            component: ProductCategoryList,
            props: true,
            meta: {
              label: "Categories",
              breadcrumbs: ["product-categories"],
            },
          },
          {
            path: ":id",
            name: "product-category",
            component: ProductCategoryItem,
            props: true,
            meta: {
              label: "Category",
              breadcrumbs: ["product-categories", "product-category"],
            },
          },
        ],
      },

      {
        path: "badges",
        name: "product-badges",
        component: ProductBadgeList,
        meta: {
          label: "Badges",
          breadcrumbs: ["product-badges"],
        },
      },
      {
        path: "badges/:id",
        name: "product-badge",
        component: ProductBadgeItem,
        props: true,
        meta: {
          label: "Badge",
          breadcrumbs: ["product-badges", "product-badge"],
        },
      },
      {
        path: "attributes",
        component: ProductAttributeIndex,
        children: [
          {
            path: "",
            name: "product-attributes",
            component: ProductAttributeList,
            meta: {
              label: "Attributes",
              breadcrumbs: ["product-attributes"],
            },
          },
          {
            path: ":id",
            name: "product-attribute",
            component: ProductAttributeItem,
            props: true,
            meta: {
              label: "Attribute",
              breadcrumbs: ["product-attributes", "product-attribute"],
            },
          },
        ],
      },
      {
        path: "charges",
        name: "product-charges",
        component: ProductChargesList,
        meta: {
          label: "Charges",
          breadcrumbs: ["product-charges"],
        },
      },
      {
        path: "charges/:id",
        name: "product-charge",
        component: ProductChargesItem,
        props: true,
        meta: {
          label: "Charge",
          breadcrumbs: ["product-charges", "product-charge"],
        },
      },
    ],
  },
  {
    path: "/redirect",
    name: "redirect",
    component: Redirect,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

/**========================================================================
 * *                              NOTE
 * We don't allow users from mobile devices hence, the first thing before
 * entering the route should be to check if it is mobile OS
 *========================================================================**/
router.beforeEach(async (from, to, next) => {
  // Download page pending
  // if (
  //   ["android", "ios"].includes(os) &&
  //   from.name != "download" &&
  //   from.name != "verify-account"
  // ) {
  //   next({
  //     name: "download",
  //   });
  // } else {
  if (from.params.id && from.params.id !== "+") {
    from.params.id = parseInt(from.params.id);
  }

  if (from.meta.public === true) {
    next();
  } else {
    if (getToken()) {
      next();
    } else {
      window.open(VITE_WEB_URL, "_self");
    }
  }
});

router.afterEach((to, from) => {
  if (router.app.isSubscriptionActive) {
    pageView(to, from);
  }

  localStorage.setItem("vp-last-path", to.path);
});

export default router;
