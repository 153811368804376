<template>
  <VpModal name="product-type" title="Select Product Type" _class="vp-w-1/2">
    <div class="vp-p-6">
      <div class="vp-grid vp-grid-cols-3 vp-gap-4">
        <VerticalCard
          :icon="$options.icons.Product"
          title="Simple"
          :to="{
            name: 'product',
            params: { productId: '+' },
            query: { type: 'simple', ...categoryId },
          }"
          :attrs="[
            {
              label: 'Recommended',
              value: 'A product that is unique and does not offer varieties.',
            },
            {
              label: 'Example',
              value: 'Real Estate Properties, Unique Jewellery, Paintings',
            },
          ]"
        />

        <VerticalCard
          :icon="$options.icons.ProductVariants"
          title="Variable"
          :to="{
            name: 'product',
            params: { productId: '+' },
            query: { type: 'variable', ...categoryId },
          }"
          :attrs="[
            {
              label: 'Recommended',
              value:
                'A product that is available in different size, color or measurement.',
            },
            {
              label: 'Example',
              value:
                'Apparels, Gadgets, Food items with different flavours & quantities.',
            },
          ]"
        />

        <VerticalCard
          :icon="$options.icons.Download"
          title="Digital"
          :to="{
            name: 'product',
            params: { productId: '+' },
            query: { type: 'digital', ...categoryId },
          }"
          :attrs="[
            {
              label: 'Recommended',
              value:
                'A product that is digital content or pieces of media that can be stored in a file.',
            },
            {
              label: 'Example',
              value:
                'Ebooks, Software, Audio and music, PDF documents, Online courses.',
            },
          ]"
        />
      </div>
    </div>
  </VpModal>
</template>

<script>
import VerticalCard from "components/src/vertical-card.vue";
import { Product, ProductVariants, Download } from "icons/icons.js";

export default {
  icons: {
    Product,
    ProductVariants,
    Download,
  },

  components: {
    VerticalCard,
  },

  computed: {
    categoryId() {
      return { "category-id": this.$route.params.categoryId };
    },
  },
};
</script>
