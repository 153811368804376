<template>
  <VpRequest ref="request" :get="get" #default="{ res }">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="storeCharges"
      :request-payload="{ query: $options.storeCharges }"
      class="vp-h-full"
      ref="list"
      title="Charges"
      :search="false"
      cache="productCharges"
    >
      <template #empty>
        <VpFeature the-key="PRODUCT_CHARGE" #default="{ feature }">
          <VpListEmpty :feature="feature" route="product-charge" />
        </VpFeature>
      </template>

      <template #value="{ item }">
        <p class="vp-font-bold">
          <span v-if="item.type == 'percentage'">{{ item.value }}%</span>
          <span v-else>{{ currency }} {{ item.value }}</span>
        </p>
      </template>
      <template #apply="{ item }">
        <VpSwitch
          :disabled="!checkPermission('PRODUCT', 'upsert')"
          :id="`badge-${item.id}`"
          :value="res.includes(item.id)"
          @input="toggle(item.id)"
        />
      </template>
    </VpList>
  </VpRequest>
</template>

<script>
import { mapGetters } from "vuex";
import get from "graph/product/charge/get.gql";
import toggle from "graph/product/charge/toggle.gql";
import storeCharges from "@/views/product-charges/collection.gql";

export default {
  storeCharges,
  props: {
    productId: [Number, String],
  },
  components: {},
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
      checkPermission: "user/checkPermission",
    }),
    columns() {
      return [
        { name: "name", type: "string" },
        { name: "value", type: "string" },
        { name: "apply", type: "string", fix: true },
      ];
    },
  },

  methods: {
    get() {
      return this.$query(get, {
        productId: Number(this.productId),
      }).then(({ data }) => {
        return data.productCharges.data.map((item) => Number(item.charge.id));
      });
    },

    async toggle(id) {
      try {
        await this.$mutate(toggle, {
          productId: Number(this.productId),
          chargeId: id,
        }).then(() => {
          this.$refs.list.refresh();
        });
      } catch (err) {
        console.error(err);
        this.$refs.request.refresh();
        this.$vayu.notify({
          title: "Error!",
          message:
            "There was an error while saving the data. Please refresh & try again",
          state: "danger",
        });
      }
    },
  },
};
</script>
