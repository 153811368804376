import ProductItem from "@/views/product/Item.vue";
import MediaList from "@/views/product/media/List.vue";
import Badges from "@/views/product/Badges.vue";
import AttributesList from "@/views/product/attributes/List.vue";
import VariantsList from "@/views/product/variants/List.vue";
import Bulk from "@/views/product/variants/Bulk.vue";
import VariantsItem from "@/views/product/variants/Item.vue";
import Charges from "@/views/product/Charges.vue";
import StockItem from "@/views/product/stock/Item.vue";
import History from "@/views/product/stock/History.vue";
import ContentList from "@/views/product/content/List.vue";
import ContentItem from "@/views/product/content/Item.vue";
import Reviews from "@/views/product/Reviews.vue";
import CustomFields from "@/views/product/CustomFields.vue";
import TieredPricing from "@/views/product/TieredPricing.vue";
// import OverviewItem from "@/views/product/overview-item.vue";
export default [
  {
    path: "",
    component: ProductItem,
    name: "product",
    props: true,
    meta: {
      label: "Product",
      breadcrumbs: ["products", "product"],
    },
  },

  {
    path: "media",
    component: MediaList,
    name: "product-media",
    props: true,
    meta: {
      label: "Media",
      breadcrumbs: ["products", "product", "product-media"],
    },
  },

  // {
  //   path: "description",
  //   component: OverviewItem,
  //   name: "product-description",
  //   props: true,
  //   meta: {
  //     label: "Overview",
  //     breadcrumbs: ["products", "product", "product-description"],
  //   },
  // },

  {
    path: "badges",
    component: Badges,
    name: "product-item-badges",
    props: true,
    meta: {
      label: "Badges",
      breadcrumbs: ["products", "product", "product-item-badges"],
    },
  },

  {
    path: "attributes",
    component: AttributesList,
    name: "product-item-attributes",
    props: true,
    meta: {
      label: "Attributes",
      breadcrumbs: ["products", "product", "product-item-attributes"],
    },
  },

  {
    path: "variants",
    component: VariantsList,
    name: "product-item-variants",
    props: true,
    meta: {
      label: "Variants",
      breadcrumbs: ["products", "product", "product-item-variants"],
    },
  },

  {
    path: "variants/bulk",
    component: Bulk,
    name: "product-item-variant-bulk",
    props: true,
    meta: {
      label: "Variants",
      breadcrumbs: [
        "products",
        "product",
        "product-item-variants",
        "product-item-variant-bulk",
      ],
    },
  },

  {
    path: "variants/:variantId",
    component: VariantsItem,
    name: "product-item-variant",
    props: true,
    meta: {
      label: "Variant",
      breadcrumbs: [
        "products",
        "product",
        "product-item-variants",
        "product-item-variant",
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.params.variantId && from.params.variantId !== "+") {
        from.params.variantId = parseInt(from.params.variantId);
      }
      next();
    },
  },

  {
    path: "charges",
    component: Charges,
    name: "product-item-charges",
    props: true,
    meta: {
      label: "Charges",
      breadcrumbs: ["products", "product", "product-item-charges"],
    },
  },

  {
    path: "stock",
    component: StockItem,
    name: "product-item-stock",
    props: true,
    meta: {
      label: "Manage Stock",
      breadcrumbs: ["products", "product", "product-item-stock"],
    },
  },

  {
    path: "stock/history",
    component: History,
    name: "product-item-stock-history",
    props: true,
    meta: {
      label: "History",
      breadcrumbs: [
        "products",
        "product",
        "product-item-stock",
        "product-item-stock-history",
      ],
    },
  },
  {
    path: "content",
    component: ContentList,
    name: "product-content",
    props: true,
    meta: {
      label: "Content",
      breadcrumbs: ["products", "product", "product-content"],
    },
  },

  {
    path: "content/:fileId",
    component: ContentItem,
    name: "product-content-item",
    props: true,
    meta: {
      label: "Content",
      breadcrumbs: [
        "products",
        "product",
        "product-content",
        "product-content-item",
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.params.fileId && from.params.fileId !== "+") {
        from.params.fileId = parseInt(from.params.fileId);
      }
      next();
    },
  },

  {
    path: "reviews",
    component: Reviews,
    name: "product-item-reviews",
    props: true,
    meta: {
      label: "Reviews",
      breadcrumbs: ["products", "product", "product-item-reviews"],
    },
  },
  {
    path: "custom-fields",
    component: CustomFields,
    name: "product-custom-fields",
    props: true,
    meta: {
      label: "Custom Fields",
      breadcrumbs: ["products", "product", "product-custom-fields"],
    },
  },
  {
    path: "tiered-pricing",
    component: TieredPricing,
    name: "product-tiered-pricing",
    props: true,
    meta: {
      label: "Tiered Pricing",
      breadcrumbs: ["products", "product", "product-tiered-pricing"],
    },
  },
];
