<template>
  <div>
    <VpField :note="note">
      <div class="vp-grid vp-grid-cols-12 vp-gap-2">
        <VpField
          vid="regularPrice"
          optional
          name="Regular Price"
          rules="positiveNonZeroFloat"
          label="Regular Price"
          class="vp-col-span-6"
        >
          <VpInput :after="currency">
            <VpTextBox
              type="number"
              step="0.01"
              :value="regularPrice"
              @input="$emit('update:regularPrice', parseFloat($event))"
            />
          </VpInput>
        </VpField>
        <VpField
          :rules="priceRule"
          label="Selling Price"
          name="Selling Price"
          class="vp-col-span-6"
        >
          <VpInput :after="currency">
            <VpTextBox
              type="number"
              step="0.01"
              :value="price"
              @input="$emit('update:price', parseFloat($event))"
            />
          </VpInput>
        </VpField>
      </div>
    </VpField>
    <div class="vp-mt-2 vp-flex" v-if="regularPrice && discount">
      <p class="vp-text-gray-500">
        Discount:
        <span class="vp-text-warning-500">{{ discount }}%</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDiscountPrice } from "@/utils/index.js";

export default {
  props: {
    price: [Number, String],
    regularPrice: [Number, String],
    isNoteEnable: {
      type: Boolean,
      default: true,
    },
    attrKey: {
      type: [Number, String],
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),
    priceRule() {
      if (!Number(this.regularPrice)) {
        return "required";
      }
      return `required|max_value:${this.regularPrice}`;
    },
    discount() {
      return getDiscountPrice(this.price, this.regularPrice);
    },
    note() {
      return this.isNoteEnable
        ? "'Regular Price' will be striked out and 'Selling Price' will be used as a final price. Helpful when offering a product at a discounted rate."
        : "";
    },
  },
};
</script>
